@import './variables.scss';

@mixin screenS {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin screenM {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin screenL {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin screenXL {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin screenXXL {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin screenMaxWidth {
  @media (min-width: calc($siteMaxWidth + (2 * $paddingLarge))) {
    @content;
  }
}

.front {
  color: black;
  font-family: $mainFontFamily;
  font-size: $mainFontSize;
  margin: 0;
  padding: 0;
  background-color: white;

  @include screenXXL() {
    font-size: $mainFontSize;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $mainFontFamily;
    font-weight: 400;
    color: white;
  }

  p,
  a,
  li {
    font-size: 12px;

    @include screenM {
      font-size: 14px;
    }

    @include screenL {
      font-size: $mainFontSize;
    }
  }

  h1 {
    font-size: 26px;
    line-height: 32px;

    @include screenL {
      font-size: 40px;
      line-height: 44px;
    }

    @include screenXXL() {
      font-size: 56px;
      line-height: 60px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 24px;

    @include screenL {
      font-size: 32px;
      line-height: 36px;
    }

    @include screenXXL() {
      font-size: 44px;
      line-height: 48px;
    }
  }

  .leaderboard {
    width: 100%;
  }

  .leaderboard-table {
    font-size: 12px;
    border-collapse: collapse;

    @include screenL {
      font-size: 16px;
    }

    @include screenXL {
      font-size: 22px;
    }

    tr {
      td {
        border-bottom: 1px solid gray;
        padding: 14px 2px;
      }
    }
  }

  .highlight {
    color: $primaryColorLight;
  }

  .list-bulleted {
    list-style-type: none;
  }

  .list-bulleted li::before {
    content: '\2022';
    color: $primaryColorLight;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .cform {
    width: 100%;
    max-width: 760px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    label {
      font-size: 11px;
      margin-bottom: 4px;
      color: $primaryColor;

      @include screenL {
        margin-bottom: 8px;
        font-size: inherit;
      }
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea,
    select {
      margin-bottom: 8px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      padding: 4px 0;
      font-family: $mainFontFamily;
      font-size: 11px;
      color: rgb(40, 40, 40);
      border-radius: 0;

      @include screenL {
        margin-bottom: 20px;
        padding: 12px 0;
        font-size: inherit;
      }

      &:focus {
        outline: none;
      }
    }

    input[type='submit'] {
      background-color: $primaryColorLight;
      border: none !important;
      color: white;
      padding: 8px 12px !important;
      border-radius: 8px !important;
      font-size: 11px !important;
      font-family: $mainFontFamily;
      height: 54px !important;

      @include screenXL {
        font-size: 17px !important;
        padding: 12px 22px !important;
        height: auto !important;
      }

      &:hover {
        @include screenXL {
          text-decoration: none !important;
          background-color: black !important;
          color: white !important;
          cursor: pointer;
        }
      }
    }

    span.hint {
      font-size: 11px;
      margin-bottom: 4px;
      color: lightgray;
      font-style: italic;

      @include screenL {
        margin-bottom: 8px;
        font-size: inherit;
      }
    }

    ul.error {
      color: red;
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin-bottom: 30px;
    }
  }
}

.app {
  .bottommargin {
    margin-bottom: 86px;
  }
  .content {
    font-family: Helvetica, Arial, sans-serif;
    padding: 12px;

    @include screenL {
      padding: 36px;
    }
  }

  .buttonlist {
    display: flex;
    flex-direction: column;
  }

  .imgbar {
    width: 100%;
    min-height: 160px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .titlebar {
    min-width: 100%;
    background-color: #d7df23;
    color: rgb(67, 67, 67);
    padding-left: 14px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include screenL {
      height: 72px;
      padding-left: 36px;
    }

    h1 {
      font-size: 22px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
      font-family: 'Helvetica', 'Roboto', Arial, sans-serif;
    }

    p.teamname {
      margin-top: 0;
      font-size: 11px;
    }
  }

  .app-menu {
    ul {
      li {
        a {
          text-decoration: none !important;
        }
      }
    }
  }

  .crumbs {
    a {
      color: #50b848;
      text-decoration: none;
      font-weight: bold;
    }
  }

  .leaderboard-table {
    border-collapse: collapse;
    font-size: 12px;

    @include screenL {
      font-size: 16px;
    }

    tr {
      td {
        border-bottom: 1px solid gray;
        padding: 14px 2px;
        a {
          color: black;
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }

        span {
          display: inline-block;

          &.myteam {
            background-color: #50b848;
            text-decoration: none;
            padding: 4px 12px;
            border-radius: 6px;
            font-weight: bold;
          }
        }
      }

      &.myscore td {
        background-color: #d7df23;
        font-weight: bold;
      }
    }
  }
}
