@import '../base.scss';

.container-fw {
  width: 100%;
  padding: 0 $paddingSmall;

  @include screenL {
    padding: 0 $paddingLarge;
  }
}

.container-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-maxw {
  width: 100%;
  max-width: $siteMaxWidth;
}

.no-padding {
  padding: 0;
}

.vpadding {
  padding-top: 8px;
  padding-bottom: 8px;

  @include screenM {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include screenL {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include screenXXL {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.bg-alt {
  background-color: #f7f7f7;
}

.hide-mobile {
  display: none !important;

  @include screenL {
    display: inherit !important;
  }
}

.hidden {
  display: none !important;
}

.img-fw {
  width: 100%;
  height: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  display: flex;
  align-items: flex-end;
  color: white;
  position: relative;
  overflow: hidden;
  max-width: 680px;

  @include screenL {
    height: 240px;
  }

  @include screenXXL {
    height: 340px;
  }
}
