$primaryColor: #00608a;
$darkTextColor: #303030;
$primaryColorLight: #0084ad;

/************************************
    Fonts
************************************/

$mainFontFamily: 'Roboto', Helvetica, sans-serif;
$secondaryFontFamily: 'Roboto', Helvetica Bold, sans-serif;
$appFontFamily: 'Dock11', 'Roboto', Helvetica Bold, sans-serif;
$mainFontSize: 17px;
$navigationFontSize: 18px;

/************************************
    Layout
************************************/

$paddingLarge: 40px;
$paddingSmall: 12px;
$siteMaxWidth: 1680px;
