@import '../base.scss';

.btn {
  display: inline-block;
  background-color: $primaryColorLight;
  color: white;
  padding: 8px 6px;
  border-radius: 8px;
  font-size: 11px;
  font-family: $mainFontFamily;
  letter-spacing: 0;
  border: none !important;

  @include screenM {
    padding: 8px 12px;
    letter-spacing: 1px;
  }

  @include screenXL {
    font-size: 17px;
    padding: 12px 22px;
  }

  &:hover {
    @include screenXL {
      text-decoration: none !important;
      background-color: black !important;
      color: white !important;
      cursor: pointer;
    }
  }

  &.btn-inverse {
    background-color: transparent;
    color: $primaryColorLight;

    &:hover {
      @include screenXL {
        background-color: $primaryColorLight;
        color: white;
      }
    }
  }
}
