.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9980;

  .inner {
    padding: 30px;
    background-color: white;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;

    .spinner {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #007aff;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      animation: spin 2s linear infinite;
    }
  }
}
