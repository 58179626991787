@font-face {
  font-family: 'Antwerpen';
  font-style: normal;
  src: url('./fonts/Antwerpen-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Antwerpen-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Antwerpen-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/Antwerpen-Regular.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./fonts/Antwerpen-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/Antwerpen-Regular.svg#Antwerpen-Regular') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Dock11';
  font-style: normal;
  src: url('./fonts/Dock11.ttf');
  font-display: swap;
}
