@import '../base.scss';

.subtitle {
  font-size: 16px;
  line-height: 20px;
  color: white;

  @include screenL {
    font-size: 22px;
    line-height: 26px;
  }

  @include screenXXL() {
    font-size: 28px;
    line-height: 36px;
  }
}

.text-centered {
  text-align: center;
}

.text-description {
  p,
  span,
  a {
    margin: 0;
    padding: 0;
    font-size: 12px !important;

    @media only screen and (min-width: 768px) {
      font-size: 16px !important;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 18px !important;
    }
  }
}

.text-description-small {
  p,
  span,
  a {
    margin: 0;
    padding: 0;
  }
  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
  }
}
